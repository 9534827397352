import './style.css'
import * as THREE from 'three'
import * as dat from 'lil-gui'
import { GridHelper } from 'three'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import gsap from 'gsap'

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()
const rayOrigin = new THREE.Vector3(10, 0, 0)
const rayDirection = new THREE.Vector3(20, 0, 0)
rayDirection.normalize()

raycaster.set(rayOrigin, rayDirection)

/**
 * Debug
 */

/*
const gui = new dat.GUI()
*/

const parameters = {
    materialColor: '#9babc5'
}

/** 

gui
    .addColor(parameters, 'materialColor')
    .onChange(() =>
    {
        material.color.set(parameters.materialColor)
        particlesMaterial.color.set(parameters.materialColor)
    })
*/
/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 *  Objects
 */

//Texture 
const textureLoader = new THREE.TextureLoader()
const gradientTexture = textureLoader.load('textures/gradients/3.jpg')

gradientTexture.magFilter = THREE.NearestFilter

const urls = [
    "textures/book/front.png", "textures/book/back.png",
    "textures/book/top.png", "textures/book/bottom.png",
    "textures/book/edge.png", "textures/book/spine.png"
]

const bookMaterials = urls.map(url => {
    return new THREE.MeshBasicMaterial({
        map: textureLoader.load(url)
    })
})

const material = new THREE.MeshToonMaterial({
    color: parameters.materialColor,
    gradientMap: gradientTexture
})
const objectDistance = 4

// Meshes 
const mesh1 = new THREE.Mesh(
    new THREE.TorusGeometry(1, 0.4, 16, 60),
    material

)

const mesh2 = new THREE.Mesh(
    new THREE.TorusGeometry(1, 0.4, 16, 60),
    material
)

const mesh3 = new THREE.Mesh(
    new THREE.OctahedronGeometry(0.4, 0),
    material
)

const mesh4 = new THREE.Mesh(
    new THREE.OctahedronGeometry(0.4, 0),
    material
)

const exploreTorus = new THREE.Mesh(
    new THREE.TorusGeometry(1, 0.4, 16, 60),
    material
)
scene.add(exploreTorus)
exploreTorus.position.y = - objectDistance * 4 - 0.7

const placeHolder = new THREE.Mesh(
    new THREE.TorusGeometry(1, 0.4, 16, 60),
    material
)

const exitBox = new THREE.Mesh(
    new THREE.BoxGeometry(1, 1, 1),
    material
)
exitBox.position.x = 18.5
exitBox.position.y = 0
scene.add(exitBox)



const explore = document.getElementById('start').addEventListener('click', initScene)
var explorer = false;
var state = 0;
function initScene(){
    console.log('hi')
    document.getElementById('education').style.display = "none";
    document.getElementById('experience').style.display = "none";
    document.getElementById('about').style.display = "none";
    document.getElementById('menu').style.display = "none";
    document.getElementById('my-name').style.display = "none";
    if(explorer){
        state = 0
        camera.position.x = 0
        explorer = false
        document.getElementById('education').style.display = "block";
        document.getElementById('experience').style.display = "block";
        document.getElementById('about').style.display = "block";
        document.getElementById('menu').style.display = "block";
        document.getElementById('my-name').style.display = "block";
        document.getElementById('start').innerHTML = 'START EXPLORE'
        if(state == 0){
            console.log(state)
        }
        console.log("i am back")
        console.log(state)
        console.log(camera.position.x)
        console.log(camera.position.y)
    }
    else{
        state = 1;
        document.getElementById('start').innerHTML = 'DONE EXPLORING'
        camera.position.x =10

        if(state = 1){
            camera.position.y = 0
        }

        //Mouse 
        window.addEventListener('wheel', onMouseWheel)
        let x = 0
        let position = 9.9
        function onMouseWheel(event){
            x = event.deltaY * 0.002
            
            if(state == 1){
                window.scrollTo(0,0)
            }
        }
        window.scrollTo(0, 0);
        if (state == 1){
            console.log(state);
            const tick1 = () =>
            {
                const elapsedTime = clock.getElapsedTime()
                const deltaTime = elapsedTime - previousTime
                previousTime = elapsedTime
                if (state == 1){
                    if(position < 9.9){
                        position = 9.9
                    }
                    else if(position >= 19){
                        position = 19
                    }
                    position += x
                    x *=.9
                    camera.position.x = position
                }
                // Render
                renderer.render(scene, camera)
    
                // Call tick again on the next frame
                if(state == 1){
                    window.requestAnimationFrame(tick1)
                }
            }
            tick1()
        }
        explorer = true
    }
}


const video = document.getElementById("fight-video");
video.load();
document.addEventListener("keydown", onDocumentKeyDown, false);
function onDocumentKeyDown(event) {
    if (event.keyCode == 80) {
        if(camera.position.x >=13.5){
            video.play();  
        }
        else if(camera.position.x >= 9.8)
        {
            video2.play();
        }
    }
    if(event.keyCode == 32){
        event.preventDefault();
        if(camera.position.x >=13.5){
            video.pause();  
        }
        else if(camera.position.x >= 9.8)
        {
            video2.pause();
        }
    }
};



//video
const videoTexture = new THREE.VideoTexture(video);
videoTexture.needsUpdate = true;
const videoMaterial = new THREE.MeshBasicMaterial({
    map: videoTexture,
    side: THREE.FrontSide,
    toneMapped: false,
});
videoMaterial.needsUpdate = true;

//Create screen
const screen = new THREE.PlaneGeometry(3.6, 2.4);
const videoScreen = new THREE.Mesh(screen, videoMaterial);
videoScreen.position.y = -0.25
videoScreen.position.x = 14.5
scene.add(videoScreen);

const video2 = document.getElementById("huanted-video");
video2.load();


//video
const videoTexture2 = new THREE.VideoTexture(video2);
videoTexture2.needsUpdate = true;
const videoMaterial2 = new THREE.MeshBasicMaterial({
    map: videoTexture2,
    side: THREE.FrontSide,
    toneMapped: false,
});
videoMaterial2.needsUpdate = true;

//Create screen
const screen2 = new THREE.PlaneGeometry(3.6, 2.4);
const videoScreen2 = new THREE.Mesh(screen2, videoMaterial2);
videoScreen2.position.y = -0.25
videoScreen2.position.x = 10
scene.add(videoScreen2);









const bookGeometry = new THREE.BoxGeometry(0.2, 2, 1.35)
const book = new THREE.Mesh(bookGeometry, bookMaterials)

var loader = new FontLoader();

//Huanted House Text
loader.load( '/fonts/gentilis_regular.typeface.json', function ( font ) {   
    var textMessages = 'The Huanted House';
    var textSizes = 0.12;
    var textsShapes = font.generateShapes( textMessages, textSizes );
    var textsGeometry = new THREE.ShapeBufferGeometry( textsShapes );    
    var textsMaterial = new THREE.MeshBasicMaterial({color: 0xeeeeee});
    textsGeometry.center()
    var text = new THREE.Mesh(textsGeometry, textsMaterial);
    text.position.set(10, 1, 1);
    scene.add(text);

}); 

//Huanted House Text
loader.load( '/fonts/gentilis_regular.typeface.json', function ( font ) {   
    var textMessages = '2D Game Development';
    var textSizes = 0.12;
    var textsShapes = font.generateShapes( textMessages, textSizes );
    var textsGeometry = new THREE.ShapeBufferGeometry( textsShapes );    
    var textsMaterial = new THREE.MeshBasicMaterial({color: 0xeeeeee});
    textsGeometry.center()
    var text = new THREE.Mesh(textsGeometry, textsMaterial);
    text.position.set(14.5, 1, 1);
    scene.add(text);

}); 

//Instruction 1
loader.load( '/fonts/gentilis_regular.typeface.json', function ( font ) {   
    var textMessages = 'Press P to Start, Press Space to Pause';
    var textSizes = 0.12;
    var textsShapes = font.generateShapes( textMessages, textSizes );
    var textsGeometry = new THREE.ShapeBufferGeometry( textsShapes );    
    var textsMaterial = new THREE.MeshBasicMaterial({color: 0xeeeeee});
    textsGeometry.center()
    var text = new THREE.Mesh(textsGeometry, textsMaterial);
    text.position.set(10, -1.45, 1);
    scene.add(text);

}); 

//Instruction 2
loader.load( '/fonts/gentilis_regular.typeface.json', function ( font ) {   
    var textMessages = 'Press P to Start, Press Space to Pause';
    var textSizes = 0.12;
    var textsShapes = font.generateShapes( textMessages, textSizes );
    var textsGeometry = new THREE.ShapeBufferGeometry( textsShapes );    
    var textsMaterial = new THREE.MeshBasicMaterial({color: 0xeeeeee});
    textsGeometry.center()
    var text = new THREE.Mesh(textsGeometry, textsMaterial);
    text.position.set(14.5, -1.45, 1);
    scene.add(text);

}); 

//exitBox instruction
loader.load( '/fonts/gentilis_regular.typeface.json', function ( font ) {   
    var textMessages = 'Click on box to exit';
    var textSizes = 0.12;
    var textsShapes = font.generateShapes( textMessages, textSizes );
    var textsGeometry = new THREE.ShapeBufferGeometry( textsShapes );    
    var textsMaterial = new THREE.MeshBasicMaterial({color: 0xeeeeee});
    textsGeometry.center()
    var text = new THREE.Mesh(textsGeometry, textsMaterial);
    text.position.set(18.5, -1.2, 1);
    scene.add(text);

}); 



const fontLoader = new FontLoader()
fontLoader.load(
   '/fonts/gentilis_regular.typeface.json',
   (font) =>
   {
       const textGeometry1 = new TextGeometry(
           'Web Crawler Project Demo',
           {
               font: font,
               size: 0.15,
               height: 0.1,
               bevelSegments: 5
           }
       )
       const textGeometry2 = new TextGeometry(
        'Press P to start, Press space to stop',
        {
            font: font,
            size: 0.15,
            height: 0.2,
        }
    )
       textGeometry1.computeBoundingBox()
       textGeometry1.center()
       textGeometry2.computeBoundingBox()
       textGeometry2.center()
       const textMaterial = new THREE.MeshBasicMaterial({
           color: 'skyblue'
       })

       const text1 = new THREE.Mesh(textGeometry1, textMaterial)
       const text2 = new THREE.Mesh(textGeometry2, textMaterial)
       //scene.add(text1, text2)
       text1.position.y = 1.25
       text1.position.x = 10
       text1.position.z = 0
       text2.position.y = 0
       text2.position.x = 10
       text2.position.z = 0
   }
)


mesh1.position.x = 1.8
mesh1.position.y = objectDistance * 0
mesh2.position.y = - objectDistance * 1
mesh2.position.x = -2
mesh3.position.y = - objectDistance * 2 -0.5
mesh3.position.x = 2.6
mesh4.position.y = - objectDistance * 2 -0.5
mesh4.position.x = -2.6
book.position.y = - objectDistance * 3 -1
book.position.x = 2.3
book.rotation.y = Math.PI + 1.3
scene.add(mesh1, mesh2, mesh3, mesh4, book)
const sectionMeshes = [mesh1, mesh2, placeHolder, placeHolder, exploreTorus]
const sameLevelMeshes = [mesh3, mesh4]

/**
 * particles 
 */
// Geometry 
const particlesCount = 200
const positions = new Float32Array(particlesCount * 3)

for(let i =0;i<particlesCount; i++){
    // x
    positions[i*3 + 0] = (Math.random() - 0.5) * 10
    // y
    positions[i*3 + 1] = objectDistance * 0.5 - Math.random() * objectDistance * 6
    // z
    positions[i*3 + 2] = (Math.random() - 0.5) * 10
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

//Material 

const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 0.03
})

// Points

const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Lights
 */
const directionLight = new THREE.DirectionalLight('#ffffff', 1)
directionLight.position.set(1, 1, 0)
scene.add(directionLight)

const pointLight = new THREE.PointLight(0xff9000, 0.01)
pointLight.position.set(-2, -12, 1)
scene.add(pointLight)


/**
 * Mouse
 */

 const mouse = new THREE.Vector2()
 let currentIntersect = null;
 window.addEventListener('click', () =>
{
    if(currentIntersect)
    {
        switch(currentIntersect.object)
        {
            case exitBox:
                state = 0
                camera.position.x = 0
                camera.position.y = -4
                document.getElementById('education').style.display = "block";
                document.getElementById('experience').style.display = "block";
                document.getElementById('about').style.display = "block";
                document.getElementById('menu').style.display = "block";
                document.getElementById('my-name').style.display = "block";
                document.getElementById('start').innerHTML = 'START EXPLORE'
                break

            case videoScreen:
                break

            case videoScreen2:
                break
        }
    }
})

 window.addEventListener('mousemove', (event) =>
 {
     mouse.x = event.clientX / sizes.width * 2 - 1
     mouse.y = - (event.clientY / sizes.height) * 2 + 1
 
 })


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */

//Group 
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Scroll
 */
let scrollY = window.scrollY
let currentSection = 0

window.addEventListener('scroll', () =>{
    scrollY = window.scrollY
    const newSection = Math.round(scrollY / sizes.height)
    if(newSection != currentSection){
        currentSection = newSection
        if(currentSection!=5){
            if(currentSection == 2){
                for(const mesh of sameLevelMeshes){
                    gsap.to(
                        mesh.rotation,{
                            duration: 1.5,
                            ease: 'power2.inOut',
                            x: '+=6',
                            y: '+=4'
                        }
                    )
                }
            }
            else if(currentSection == 3){
                gsap.to(
                    book.rotation,{
                        duration: 1.5,
                        ease: 'power2.inOut',
                        x: '+=0.1',
                        y: '+=6'
                    }
                )
            }
            else{
                gsap.to(
                    sectionMeshes[currentSection].rotation,{
                        duration: 1.5,
                        ease: 'power2.inOut',
                        x: '+=6',
                        y: '+=6'
                    }
                )
            }
        }
    }
})

/*
// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
*/


/**
 * Crusor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) =>{
    cursor.y = event.clientY / sizes.height - 0.5
    cursor.x = event.clientX / sizes.width - 0.5
})

//send 
document.getElementById('contact-form').addEventListener('submit', function(event) {
    event.preventDefault();
                // Get form values
    var name = document.getElementsByName('name')[0].value;
    var email = document.getElementsByName('email')[0].value;
    var phone = document.getElementsByName('phone')[0].value;
    var message = document.getElementsByName('message')[0].value;

    // Construct email body
    var emailBody = `
        Name: ${name} <br>
        Email: ${email} <br>
        Phone: ${phone} <br>
        Message: ${message}
    `;
    Email.send({
        Host : "smtp.elasticemail.com",
        Username : "kcbs96199@gmail.com",
        Password : "965F1BCB1CA16EF44C8F40291FD8370D350F",
        To : 'kcbs96199@gmail.com',
        From : 'kcbs96199@gmail.com',
        Subject : "New Contact Form Submission",
        Body : emailBody
    }).then(
      message => {if (message == "OK"){
        Swal.fire({
            title: "Success",
            text: "Message send successfully",
            icon: "success"
          });
        }
    }
    ).catch(
        error => alert("Failed to send message: " + error)
    );
});


const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Animate Camera
    
    camera.position.y = - scrollY / sizes.height * objectDistance

    const parallaxX = cursor.x
    const parallaxY = - cursor.y
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 2 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 2 * deltaTime
    //Animate Meshes
    for(const mesh of sectionMeshes){
        mesh.rotation.x += deltaTime * 0.1
        mesh.rotation.y += deltaTime * 0.12
    }

    //Animate 3
    for(const mesh of sameLevelMeshes){
        mesh.rotation.x += deltaTime * 0.1
        mesh.rotation.y += deltaTime * 0.12
    }

    exitBox.rotation.x += deltaTime * 0.1
    exitBox.rotation.y += deltaTime * 0.12

    //Raycaster
    raycaster.setFromCamera(mouse, camera)
    const objectsToTest = [exitBox, videoScreen, videoScreen2]
    const intersects = raycaster.intersectObjects(objectsToTest)
    for(const intersect of intersects)
    {
        intersect.object.scale.set(1.1, 1.1)
        //intersect.object.material.color.set('#0000ff')
    }

    for(const object of objectsToTest)
    {
        if(!intersects.find(intersect => intersect.object === object))
        {
            object.scale.set(1,1)
        }
    }

    if(intersects.length)
    {
        if(!currentIntersect)
        {
            //console.log('mouse enter')
        }

        currentIntersect = intersects[0]
    }
    else
    {
        if(currentIntersect)
        {
            //console.log('mouse leave')
        }
        
        currentIntersect = null
    }
    

    // update control
    //Animate 4

    book.rotation.y += deltaTime * 0.8
    book.rotation.x += deltaTime * 0.001
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()